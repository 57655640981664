import logo from './logo.svg';
import './App.css';

import React, { useState, useEffect } from "react";


import axios from 'axios';

import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Figure from 'react-bootstrap/Figure';
import Image from 'react-bootstrap/Image';
import ListGroup from 'react-bootstrap/ListGroup';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';


import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';

import 'bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";


import ring_shg from './assets/images/freqcomb_shg.jpg'
import chaotic_comb from './assets/images/comb.jpg'
import soliton_comb from './assets/images/comb.png'
import pic_image from './assets/images/pic.jpg'

import { Chart } from "react-google-charts";
import { Col, Row } from 'react-bootstrap';

import { KoFiButton } from "react-kofi";
import "react-kofi/dist/styles.css";


import { Helmet } from 'react-helmet';


function App() {
  //TODO: Update this
  if (window.location.host.split(".")[0] === "tekkenratings") {

    return (
      <Router>
        <Helmet>
          <title>Tekken 8 Offline Ratings</title>
        </Helmet>
        <main>
          <div className="App">
            <NavBarHeading/>
          </div>
          <Routes>
            <Route path="/" element={<RatingsPage/>} />
          </Routes>
        </main>
      </Router>
    );
  }
  else {
    return (
      <Router>
        <title>Tristan's Personal Site</title>
        <main>
          <div className="App">
            <NavBarHeading/>
          </div>
          <Routes>
            <Route path="/" element={<HomePage/>} />
            <Route path="/research" element={<ResearchPage/>} />
            <Route path='/personal-projects' element={<PersonalProjectsPage/>} />
            <Route path='/contact-me' element={<ContactMePage/>} />
          </Routes>
          <script matomo = {{
            __html:
              !function() {
                var _paq = window._paq = window._paq || [];
                /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
                _paq.push(['trackPageView']);
                _paq.push(['enableLinkTracking']);
                (function() {
                  var u="//tristanmelton.com/matomo/";
                  _paq.push(['setTrackerUrl', u+'matomo.php']);
                  _paq.push(['setSiteId', '1']);
                  var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
                  g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
                })();    
              }
          }} />
        </main>
      </Router>
    );
  }
}


function NavBarHeading() {
  return (
    <Navbar expand="lg" className="bg-body-tertiary" >
      <Container fluid>
        <Navbar.Brand href="https://tristanmelton.com/">Tristan Melton</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="https://tristanmelton.com/research">Research</Nav.Link>
            <NavDropdown title="Personal Projects" id="basic-nav-dropdown">
              <NavDropdown.Item href="https://tekkenratings.tristanmelton.com">Tekken 8 Tournament Ratings</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="https://tristanmelton.com/personal-projects">Old Personal Projects Page</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="https://tristanmelton.com/contact-me">Contact Me</Nav.Link>
            <NavDropdown title="Wikis" id="basic-nav-dropdown">
              <NavDropdown.Item href="https://tristanmelton.com/dokuwiki/doku.php?id=start">Fighting Game Wiki</NavDropdown.Item>
              <NavDropdown.Item href="https://tristanmelton.com/wong_lab/doku.php?id=start">Frequency Comb Subgroup Wiki</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

function HomePage() {
  return (
    <>
      <h1 style={{textAlign:'center'}}>Hi, my name is Tristan Melton.</h1>
      <div className="HomePageContent" style={{flex:1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width:'100rem', margin: 'auto'}}> 
        <div className="HomePage">
          <p style={{fontSize: '1.25rem'}}>
            I am a PhD candidate at in the Mesoscopic Optics and Quantum Electronics Laboratory at UCLA, under the direction of Professor Chee Wei Wong. My research interests
            surround photonic devices, specifically the development of photonic integrated circuit (PIC) chips for metrology in CMOS-compatible platforms. Most of my research 
            work thus far revolves around the application of Kerr frequency microcombs in these systems and methods of generation and stabilization.  My work can be found on the
            research tab of this site!
          </p>
        </div>
        <div className="HomePageFigures" style={{width:'60%'}}>
          <Figure>
            <Figure.Image
              alt="Second harmonic generation in a microresonator"
              src={ring_shg}
            />
            <Figure.Caption style={{textAlign: 'center'}}>
              Second harmonic generation from 1064-nm pump laser in a high quality factor microresonator.
            </Figure.Caption>
          </Figure>
          <Figure>
            <Figure.Image
              alt="Chaotic frequency microcomb"
              src={chaotic_comb}
            />
            <Figure.Caption style={{textAlign: 'center'}}>
              Chaotic frequency comb generated from a microresonator. In this instance, the comb is chaotic in the sense that fluctuations related to the mathematical concept of 
              chaos are present in the frequency and amplitude of each comb line, and no coherent pulse is formed.
            </Figure.Caption>
          </Figure>
        </div>
      </div>
    </>
  );
}

function ResearchPage() {
  return (
    <>
      <div className="ResearchHeader" style={{textAlign:'center'}} >
        <h1>Research Work</h1>
        <p>
          Below you can find my research work, organized into groupings by topic. My Google Scholar page can be found <a href="https://scholar.google.com/citations?user=UTzpOhoAAAAJ&hl=en&oi=ao">here.</a>
        </p>
      </div>
      <ResearchCards/>    
      <ResearchCitations/>
    </>

  )
}
function ResearchCards() {
  return (
      <div className="ResearchCards" style={{flex:1, flexDirection: 'row', display: 'flex', justifyContent: 'center'}}>
        <Card style={{ maxWidth: '18rem', flex:1 }}>
          <Card.Img variant="top" src={soliton_comb} />
            <Card.Body>
              <Card.Title>Kerr Frequency Combs</Card.Title>
              <Card.Text>
                Research towards developing and designing Kerr frequency microcombs in Silicon Nitride. 
              </Card.Text>
            </Card.Body>
        </Card>

        <Card style={{ maxWidth: '18rem', flex:1 }}>
          <Card.Img variant="top" src={pic_image} />
            <Card.Body>
              <Card.Title>Photonic Integrated Circuits (PIC) Design</Card.Title>
              <Card.Text>
                Photonic integrated circuit design research and chip development.
              </Card.Text>
            </Card.Body>
          </Card>
      </div>
  );
}
function ResearchCitations() {
  return (
    <div className="ResearchAccordion" style={{flex:1, flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <Accordion defaultActiveKey="0" style={{ minwidth: '100rem',maxWidth: '100rem', flex:1}}>
        <Accordion.Item eventKey="0" style={{minWidth: '100rem', flex: 1}}>
          <Accordion.Header>Frequency Combs</Accordion.Header>
          <Accordion.Body>
            <h2>Accepted Journal Manuscripts</h2>
            - Alwaleed Aldhafeeri, Hsiao-Hsuan Chin, Tristan Melton, Dong IL Lee, Allen Chu, Wenting Wang, Mingbin Yu, Patrick Guo-Qiang Lo, Dim-Lee Kwong, and Chee Wei Wong, "Low phase noise K-band signal generation using polarization diverse single-soliton integrated microcombs," Photon. Res. 12, 1175-1185 (2024) <br/>
            - T. Melton, J. F. McMillan, J. Yang, W. Wang, Y. Lai, M. Gerber, M. Rodriguez, J. P. Hubschman, K. Nouri-Mahdavi, and C. W. Wong, Imaging optical coherence tomography and noise characterization based on 1-µm microresonator frequency combs, under review (2024). <br/>
            - W. Wang, H. Zhou, X. Jiang, T. Melton, A. K. Vinod, M. Yu, G.-Q. Lo, D.-L. Kwong, and C. W. Wong, Polarization-diverse soliton transitions and deterministic switching dynamics in strongly-coupled and self-stabilized microresonator frequency combs, arXiv 2303.03687 (2023). <br/>
            <h2>Accepted Conference Talks</h2>
            - T. Melton, H. Liu, W. Wang, M. Yu, D.-L. Kwong, and C. W. Wong, Observations of dual-dispersive soliton microcombs with deterministic azimuthal angle control, CLEO 2023, 1-2 (2023).<br/>
            - T. Melton, J. McMillan, W. Wang, Y. Lai, M. Gerber, M. Rodriguez, K. Nouri-Mahdavi, J. P. Hubschman, and C. W. Wong, Laser frequency microcombs for discrete-wavelength optical coherence tomography, CLEO 2023, AM3Q.1 (2023). <br/>
            - W. Wang, H. Liu, T. Melton, J. Yang, A. K. Vinod, J. Lim, Y.-S. Jang, H. Zhou, M. Yu, D.-L. Kwong, P. DeVore, J. Chou, and C. W. Wong, Sampling timing jitter in dispersion-managed frequency microcombs via a fiber interferometer, CLEO 2022, Stu1C.4 (2022). <br/>
            - T. Melton, J. McMillan, W. Wang, Y. Lai, M. Gerber, M. Rodriguez, K. Nouri-Mahdavi, J.P. Hubschman, and C. W. Wong, High-resolution millimeter-depth optical coherence tomography with 1-um 100-GHz chip-scale laser frequency microcombs, CLEO 2022 Conference <br/>
            - W. Romaszkan, T. Li, T. Melton, S. Pamarti and P. Gupta, ACOUSTIC: Accelerating convolutional neural networks through or-unipolar skipped stochastic computing, 2020 Design, Automation & Test in Europe Conference & Exhibition (DATE), Grenoble, France, 768-773 (2020). <br/>
            - W. Romaszkan, T. Li, T. Melton, S. Pamarti and P. Gupta, Machine learning at the edge using spintronic stochastic computing, GOMACTech-20 – Presentation.
          
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1" style={{minWidth: '100rem', flex: 1}}>
          <Accordion.Header>PIC Design</Accordion.Header>
          <Accordion.Body>
          <h2>Accepted Journal Manuscripts</h2>
          - T. Melton, P. T. S. DeVore, J. McMillan, J. Chan, A. Calonico-Soto, K. M. Beck, C. W. Wong, J. T. Chou, and A. Gowda, Scalable stable comb-to-tone integrated RF photonic drive for superconducting qubits, Opt. Express 32, 18761-18770 (2024). <br/>
          <h2>Accepted Conference Talks</h2>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

    </div>
  );
}
function PersonalProjectsPage() {
  let [gitProjs, setGitProjs] = useState([{name:'Loading', language: '', description: '', html_url: ''}, {name:'Loading', language: '', description: '', html_url: ''}]);
  useEffect(() => {
    axios.get('https://api.github.com/users/tristanmelton/repos?sort=created').then((responses) => {
        const repos = responses.data.map(({ name, language, html_url, created_at, description }) => {
          return { name, language, html_url, created_at, description };
        });    
        setGitProjs(repos);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <div style={{flex:1, flexDirection: 'column', display:'flex', alignContent:'center', justifyContent:'center', textAlign:'center'}}>
        <h1>Personal Projects</h1>
      </div>
      <div style={{flex:1, flexDirection: 'row', display:'flex', alignContent:'center'}} >
        <Image src='https://avatars.githubusercontent.com/u/30941629?v=4' roundedCircle fluid style={{height: '15rem'}}/>
        <p style={{flex:1, alignContent:'center', fontSize: '2rem'}}>Below are some of my projects I've been working on!</p>
      </div>
      <div style={{flex:1, flexDirection: 'row', display:'flex', alignContent:'center', justifyContent:'center'}}><h2>Programming</h2></div>
      <div className="PersonalProjects" style={{flex:1, flexDirection: 'column', display: 'flex', justifyContent: 'center'}}>
        <Card style={{flex:1, textAlign:'center'}}>
          <Card.Body>
            <Card.Title>{gitProjs[0].name}</Card.Title>
            <Card.Subtitle>{gitProjs[0].language}</Card.Subtitle>
            <Card.Text>{gitProjs[0].description}</Card.Text>
          </Card.Body>
          <Card.Body>
            <Card.Link href={gitProjs[0].html_url}>{gitProjs[0].html_url}</Card.Link>
          </Card.Body>
        </Card>
        <Card style={{flex:1, textAlign:'center'}}>
          <Card.Body>
            <Card.Title>{gitProjs[1].name}</Card.Title>
            <Card.Subtitle>{gitProjs[1].language}</Card.Subtitle>
            <Card.Text>{gitProjs[1].description}</Card.Text>
          </Card.Body>
          <Card.Body>
            <Card.Link href={gitProjs[1].html_url}>{gitProjs[1].html_url}</Card.Link>
          </Card.Body>
        </Card>
        <div style={{flex:1, flexDirection: 'row', display:'flex', alignContent:'center', justifyContent:'center'}}><h2>Electronics</h2></div>
        <div className="PersonalProjects_Electronics" style={{flex:1, flexDirection: 'column', display: 'flex', justifyContent: 'center'}}>
        <Card style={{flex:1, textAlign:'center'}}>
          <Card.Body>
            <Card.Title>{"Custom PCB for Keyboard and Keypad"}</Card.Title>
            <Card.Subtitle>{"Put Image(s) Here"}</Card.Subtitle>
            <Card.Text>{"Put Description Here"}</Card.Text>
          </Card.Body>
        </Card>
        </div>
      </div>
    </>
  );
}
function ContactMePage() {
  return (
<div style={{flex:1, flexDirection: 'column', display:'flex', alignContent:'center', justifyContent:'center', textAlign:'center'}}>
  <h1>Contact Information</h1>
  <p>Feel free to reach out to me regarding any of my prior, current, or future research plans or projects! I am always interested in hearing about new or interesting science or ideas. <br/>
  </p>
  <p>Email (for academic inquiries): tristanmelton@engineering.ucla.edu</p>
  <p>Email (for personal and all other inquiries): tristanmelton@proton.me</p>
</div>
  );
}



// RATINGS 
function RatingsPage() {
  const [ formData, setFormData ] = useState({
    slug: '',
  })
  const [ ratingData, setRatingData ] = useState({
    rating: '',
    deviation: '',
    gamertag: '',
  })
  const [ matchHistoryData, setHistoryData ] = useState([])
  const [ matchTableData, setTableData ] = useState([])

  const [ ratingDist, setRatingDist ] = useState([
    ["Rating", "Count"],
    [600, 0],
    [650, 0],
    [700, 0],
    [750, 0],
    [800, 0],
    [850, 0],
    [900, 0],
    [950, 0],
    [1000, 0],
    [1050, 0],
    [1100, 0],
    [1150, 0],
    [1200, 0],
    [1250, 0],
    [1300, 0],
    [1350, 0],
    [1400, 0],
    [1450, 0],
    [1500, 0],
    [1550, 0],
    [1600, 0],
    [1650, 0],
    [1700, 0],
    [1750, 0],
    [1800, 0],
    [1850, 0],
    [1900, 0],
    [1950, 0],
    [2000, 0],
//    [2050, 0],
//    [2100, 0],
//    [2150, 0],
//    [2200, 0],
//    [2250, 0],
//    [2300, 0],
//    [2350, 0],
  ])
  const [ ratingDistPct, setRatingDistPct ] = useState([
    ["Rating", "Percent"],
    [600, 0],
    [650, 0],
    [700, 0],
    [750, 0],
    [800, 0],
    [850, 0],
    [900, 0],
    [950, 0],
    [1000, 0],
    [1050, 0],
    [1100, 0],
    [1150, 0],
    [1200, 0],
    [1250, 0],
    [1300, 0],
    [1350, 0],
    [1400, 0],
    [1450, 0],
    [1500, 0],
    [1550, 0],
    [1600, 0],
    [1650, 0],
    [1700, 0],
    [1750, 0],
    [1800, 0],
    [1850, 0],
    [1900, 0],
    [1950, 0],
    [2000, 0],
//    [2050, 0],
//    [2100, 0],
//    [2150, 0],
//    [2200, 0],
//    [2250, 0],
//    [2300, 0],
//    [2350, 0],
  ])
  const [ devDist, setDevDist ] = useState([
    ["Deviation", "Count"],
    [30, 0],
    [40, 0],
    [50, 0],
    [60, 0],
    [70, 0],
    [80, 0],
    [90, 0],
    [100, 0],
    [110, 0],
    [120, 0],
    [130, 0],
    [140, 0],
    [150, 0],
    [160, 0],
    [170, 0],
    [180, 0],
    [190, 0],
    [200, 0],
    [210, 0],
  ])

  const ratingDistOptions = {
    title: 'Rating Distribution',
    backgroundColor: '#f7f7f7',
    hAxis: {
        title: 'Rating',
    },
    vAxis: {
        title: 'Count',
    },
    colors: ['#007bff'],
    legend: {position: 'none'},

  };
  const ratingDistPctOptions = {
    title: 'Rating Distribution, Percentage',
    backgroundColor: '#f7f7f7',
    hAxis: {
        title: 'Rating',
    },
    vAxis: {
        title: 'Percent',
    },
    colors: ['#007bff'],
    legend: {position: 'none'},

  };
  const matchHistoryOptions = {
    title: "Rating History",
    curveType: "function",
    legend: {position: 'none'},
    hAxis: {
      title: "Time",
    },
    vAxis: {
      title: "Rating",
    },
    pointSize: 6,
  };
  const matchTableOptions = {
    allowHtml: true,
    showRowNumber: true,
    chartArea: {
      height: '100%',
      width: '100%',
      top: 48,
      left: 48,
      right: 16,
      bottom: 48
    },
    height: '100%',
    width: '100%',
  };
  const devDistOptions = {
    title: 'Deviation Distribution',
    backgroundColor: '#f7f7f7',
    hAxis: {
        title: 'Rating',
    },
    vAxis: {
        title: 'Count',
    },
    colors: ['#007bff'],
    legend: {position: 'none'},

  };

  function RatingSubmit(e) {
    fetch('https://tristanmelton.com:4000/rating/'+formData["slug"], {  
    })
  .then(res => {
      if (res.ok) {
        res.json().then((data) => {
          setRatingData({
            rating: data["rating"],
            deviation: data["deviation"],
            gamertag: data["gamertag"]
          }) 
        })
      } else {
          alert("Could not find user")
      }
  })

  fetch('https://tristanmelton.com:4000/match_history/'+formData["slug"], {
  })
  .then(res => {
    if (res.ok) {
      var retdata = [["Match", "Rating"]];
      var tbldata = [["Match Time", "Rating", "Deviation"]]
      res.json().then((data) => {
        var ratings = data['ratings'];
        var devs = data['deviations'];
        var times = data['match_time'];

        for (let i = 0; i < ratings.length; i++) {
          retdata.push([i+1, ratings[i]]);

          var date = new Date(times[i]*1000);

          tbldata.push([date.toLocaleString('en-US'), ratings[i], devs[i]])
        }
        console.log("Setting match history")
        setHistoryData(retdata)
        setTableData(tbldata)
      }) 
    } else {
        alert("Problem with match history")
    }
  })

  }

  function HandleSlugChange(e) {
    const key = e.target.name;
    const value = e.target.value;
    setFormData({...formData, [key]: value})
  }

  function RatingDistrib() {
    fetch('https://tristanmelton.com:4000/statistics/bucketed_rating', {
    })
    .then(res => {
      if (res.ok) {
        var retdata = [["A", "B"]];
        var pctdata = [["a", "b"]];
        var sum = 0;
        var runningpct = 0;
        res.json().then((data) => {
          var edges = data['bin_edges'];
          var counts = data['bin_counts'];
          for (let i = 0; i < ratingDist.length; i++) {
            retdata.push([edges[i], counts[i]]);
            sum += counts[i];
          }
          for (let i = 0; i < ratingDist.length; i++) {
            pctdata.push([edges[i], 100-100*(runningpct + parseFloat(counts[i])/sum)])
            runningpct = runningpct + parseFloat(counts[i])/sum;
          }
          console.log("Setting rating stat")
          const newData = ratingDist.map((entry, index) => {
            if (index === 0) {
                return entry;
            } else {
                const newProfit = retdata[index][1];
                return [entry[0], newProfit];
            }
          });
          const newDataPct = ratingDistPct.map((entry, index) => {
            if (index === 0) {
                return entry;
            } else {
                const pctchange = pctdata[index][1];
                return [entry[0], pctchange];
            }
          });
          setRatingDist(newData)
          setRatingDistPct(newDataPct)  
        }) 
      } else {
          alert("Problem with bucketed ratings")
      }
    })
  }
  function DevDistrib() {
    fetch('https://tristanmelton.com:4000/statistics/bucketed_deviation', {
    })
    .then(res => {
      if (res.ok) {
        var retdata = [["A", "B"]];
        res.json().then((data) => {
          var edges = data['bin_edges'];
          var counts = data['bin_counts'];
          for (let i = 0; i < counts.length; i++) {
            retdata.push([edges[i], counts[i]]);
          }
          console.log("Setting deviation stat")
          const newData = devDist.map((entry, index) => {
            if (index === 0) {
                return entry;
            } else {
                const newProfit = retdata[index][1];
                return [entry[0], newProfit];
            }
        });
          setDevDist(newData)  
        }) 
      } else {
          alert("Problem with bucketed ratings")
      }
    })
  }

  function UpdateDistributions() {
    RatingDistrib();
    DevDistrib();
  }

  useEffect(() => {
    UpdateDistributions()
  }, []);

  return (
    <>
      <h1 style={{textAlign:'center'}}>Tekken 8 Offline Ratings</h1>
      <div className="RatingsContent" style={{backgroundColor: '', flex:1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width:'100%', margin: 'auto'}}> 
        <div className="RatingsPage" style={{backgroundColor: ''}}>
          <Form.Label htmlFor="ratingsapi">start.gg Gamer Tag or User Slug</Form.Label>
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon3">
            </InputGroup.Text>
            <Form.Control name="slug" aria-describedby="userinput-slug"
              onChange={HandleSlugChange}/>
            <Button variant="outline-secondary" id="rating-submit" onClick={(e) => RatingSubmit(e)}>
              Submit
            </Button>
          </InputGroup>
          <Form.Text id="userinput-slug" class="w-100" muted>
              This field is case-sensitive!
            </Form.Text>
          <Container>
            <Row>
              <Col>
                <h4>Username: {ratingData['gamertag']}</h4>
              </Col>
            </Row>
            <Row>
              <Col>
                <h4>Rating: {ratingData['rating']}</h4>
              </Col>
            </Row>
            <Row>
              <Col>
                <h4>Deviation: {ratingData['deviation']}</h4>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="Statistics" style={{backgroundColor:'', width:'50%', }}>
          <hr
              style={{
                  color: 'black',
                  backgroundColor: 'black',
                  height: 5
              }}
            />
          <Container>
            <Row>
            <h2 style={{textAlign: 'center'}}>Player Match History</h2>
            </Row>
            <Row>
              <Chart
                chartType="LineChart"
                width="100%"
                height="100%"
                data={matchHistoryData}
                options={matchHistoryOptions}
                legendToggle
              />
            </Row>
            <Row>
              <Chart
                chartType="Table"
                width="100%"
                height="200px"
                data={matchTableData}
                options={matchTableOptions}
              />
            </Row>
          </Container>
          <h2></h2>
          <hr
            style={{
                color: 'black',
                backgroundColor: 'black',
                height: 5
            }}
          />
          <Container>
            <Row>
              <h2 style={{textAlign: 'center'}}>Total Competitor Statistics</h2>
            </Row>
            <Row>
              <Col>              
                <Chart chartType="ColumnChart" width="100%" height="15rem" data={ratingDist} options={ratingDistOptions} style={{}}/>
              </Col>
              <Col>
                <Chart chartType="ColumnChart" width="100%" height="100%" data={ratingDistPct} options={ratingDistPctOptions} />
              </Col>
            </Row>
            <Row>
              <Col>
                <Chart chartType="ColumnChart" width="100%" height="100%" data={devDist} options={devDistOptions} />
              </Col>
            </Row>
          </Container>  
        </div>
      </div>
      <KoFiButton
        color="#00b4f7"
        id="tristanmelton"
        label="Support me on Ko-Fi"
        radius="12px"
      />
    </>
  );
}

export default App;
